var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("dashboard-core-app-bar", {
        model: {
          value: _vm.expandOnHover,
          callback: function($$v) {
            _vm.expandOnHover = $$v
          },
          expression: "expandOnHover"
        }
      }),
      _c("dashboard-core-drawer", {
        attrs: { "expand-on-hover": _vm.expandOnHover },
        on: {
          "update:expandOnHover": function($event) {
            _vm.expandOnHover = $event
          },
          "update:expand-on-hover": function($event) {
            _vm.expandOnHover = $event
          }
        }
      }),
      _c("dashboard-core-view"),
      _c("dashboard-core-settings", {
        model: {
          value: _vm.expandOnHover,
          callback: function($$v) {
            _vm.expandOnHover = $$v
          },
          expression: "expandOnHover"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }